




















import { Component } from 'vue-property-decorator';
import { BaseComponent } from '@/mixins/base-component';
import { FacilityService } from '@/service/facility';

@Component
export default class AccessConfigComponent extends BaseComponent {
    accessConfigId: string;
    accessConfig = [0, 0];
    facilityService = new FacilityService(null);

    created() {
        this.startFullScreenLoading();
        this.facilityService.getAccessConfig().then(res => {
            this.accessConfigId = _.get(res, 'id');
            this.accessConfig = [_.get(res, 'normalParkingRate'), _.get(res, 'emergentParkingRate')];
        }).finally(() => {
            this.stopFullScreenLoading();
        });
    }

    get AccessSlaver() {
        return this.accessConfig[1];
    }
    set AccessSlaver(val: number) {
        this.accessConfig[1] = val;
    }
    get Slider2TrackStyle() {
        return {
            left: `calc(${this.accessConfig[1]}% + 7px)`,
            right: 'auto',
            width: `calc(${100 - this.accessConfig[1]}% - 10px)`
        };
    }

    saveClick() {
        return this.facilityService.setAccessConfig(this.accessConfigId, this.accessConfig).then(res => {
            this.showMessageSuccess('保存通行配置成功');
        });
    }
}

